import React from 'react'

export default function fnGetNotificationData(error, values) {
    let mode = 'error'
    let title
    let message

    switch (error) {
        case 'INVALID_CHAPTER':
            title = 'Lección no encontrada'
            message = 'Los datos no se actualizaron, la lección no se encontró en este curso.'
            break
        case 'INVALID_ACCESS_TO_VIEW_PACKAGE':
            title = 'Paquete no encontrado'
            message = 'Al parecer el paquete ha sido eliminado de tu perfil.'
            break
        case 'AUTHENTICATION_FAILED':
            title = 'Credenciales incorrectas'
            message = 'Verifica que tus credenciales sean correctas y que tu cuenta haya sido verificada'
            break
        case 'ACCOUNT_DENIED':
            title = 'Utiliza otra dirección de correo'
            message = (
                <>
                    No es posible utilizar el correo electrónico <span className='text-mandy'>{values.email}</span>.
                </>
            )
            break
        case 'PLAN_LIMIT_REACHED':
            title = 'Limite de estudiantes alcanzado'
            message = 'No se puede crear la cuenta, el estudio no tiene capacidad para nuevos estudiantes.'
            break
        case 'EMAIL_NOT_VERIFIED':
            title = 'Usuario no verificado'
            message = (
                <>
                    No hemos podido enviar el enlace para restablecer tu contraseña a la dirección{' '}
                    <span className='text-studio'>{values.email}</span>. Si eres un usuario nuevo, asegúrate de tener tu
                    cuenta verificada.
                </>
            )
            break
        case 'PASSWORD_NOT_UPDATED':
            title = 'Contraseña incorrecta'
            message = 'Verifica que tu contraseña actual sea correcta e inténtalo nuevamente.'
            break
        case 'MISSING_ZOOM_CONFIG':
            title = 'Error: configuración de Zoom'
            message =
                'La configuración de Zoom de tu estudio es invalida, ponte en contacto con el administrador de tu estudio para resolver el problema'
            break
        case 'INVALID_DATA':
            title = 'Datos incorrectos'
            message = 'Algo salió mal; verifica que todos los campos sean correctos.'
            break
        case 'CANNOT_CANCEL_RESERVATION':
            title = 'Cancelación fallida'
            message =
                'No se pudo cancelar tu asistencia, el tiempo de cancelación ha pasado o el paquete asociado a la asistencia ya no existe.'
            break
        case 'NOT_FOUND':
        case 'UNAUTHORIZED':
            title = 'Datos no encontrados'
            message = 'La información que buscas ha sido eliminada'
            break
        case 'API_ERROR':
            title = `Error: ${values.error}`
            message = values.message
            break
        case 'PUSH_SUBSCRIPTIONS_LIMITS_REACHED':
            title = 'Límite de dispositivos alcanzado!'
            message = 'Has alcanzado el límite de dispositivos en los que puedes recibir notificaciones.'
            break
        case 'MUST_ACCEPT_TERMS':
            title = 'Acepta los términos de uso'
            message = 'Debes leer y aceptar los términos de uso del estudio.'
            break
        case 'SERVER_ERROR':
            title = '¡Error!'
            message = 'Ocurrió un error en nuestros servidores; inténtalo más tarde nuevamente.'
            break
        default:
            break
    }

    //
    // returning null avoids displaying the notification
    if (!title && !message) {
        return null
    }

    return { mode, title, message }
}
